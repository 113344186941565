import React from 'react';
import {DefaultNavigationProps, Navigation} from "./Navigation";
import HomePage from "./HomePage";
import {DefaultHeaderProps, Header} from "./Header";


import './css/App.css';

function App() {
    return (
        <div className="App">
            <div className="wrapper">
                <Header blurbs={DefaultHeaderProps.blurbs}/>
                <Navigation navLinks={DefaultNavigationProps.navLinks}/>
                <HomePage/>
            </div>
        </div>
    )
}

export default App;
